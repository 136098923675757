import * as _ from "lodash";
import { MenuAlertComponent } from "./menu-alert/menu-alert.component";

export interface IWithLastknownStatus {
  lastKnowState?: string;
}
export interface IWithOperatingHours {
  operatingFrom?: string;
  operatingTo?: string;
}
export interface IWithTruckStatus
  extends IWithLastknownStatus,
    IWithOperatingHours {}
export interface IWithDeliveryTimes {}

export interface GeoLocationDto {
  lng?: number;
  lat?: number;
}
export interface SearchResult<T> {
  items: T[];
}
export interface Address {
  selectedAddressId?: number;
  title?: string;
  type?: string;
  addressLine1?: string;
  area?: number;
  avenue?: string;
  areaId?: number;
  street?: string;
  buildingNo?: string;
  blockNo?: string;
  floorNo?: string;
  flatNo?: string;
  location?: GeoLocationDto;
  countryId?: number;
  governateId?: number;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  instructions?: string;
  areaName?: LocalizedField;
  countryCode?: string;
  notes?: string;
  countryName?: LocalizedField;
  governateName?: LocalizedField;
}
export interface ApiResult<T> {
  totalCount: number;
  pageCount: number;
  items: T[];
}
export interface Location {
  lat: number;
  lng: number;
}
export interface Area {
  name: LocalizedField;
  location: Location;
  id: number;
  deliveryCharge?: number;
  boundaries?: string;
  entityState: string;
  areaPaymentMethod: string;
}

export interface TruckArea {
  area: Area;
  id: number;
  deliveryCharge?: number;
  cateringCharge?: number;
  entityState: string;
  boundaries: string;
}

export interface governate {
  name: LocalizedField;
  id: number;
  entityState: string;
  areas: Area[];
  flag: boolean;
}

export interface SubmitOrderRes {
  orderId: number;
  orderNumber?: string;
  paymentUrl?: string;
  type?: string;
}
export interface TruckPic {
  pic?: string;
  linkedItem?: MenuItemInfo;
  linkedCategory?: CategoryInfo;
  linkedUrl?: string;
  sortNo: number;
  showOnSlider: boolean;
  isMain: boolean;
}

export interface OperatingTime {
  state?: string;
  from: string;
  to: string;
}
export interface OrderInfo {
  $truck?: TruckInfo;
  serviceType?: string;
  paymentMethod?: string;
  pickupAddress?: Address;
  pickupTime?: any;
  pickupTimeTo?: any;
  expectedDeliveredAt?: any;
  truckId?: number;
  branchId?: number;
  contactName?: string;
  contactPhone?: string;
  deliveryCharge?: number;
  promoCode?: string;
  callBackUrl?: string;
  substitution?: string;
  membershipNo?: string;
  civilId?: string;
  fileNumber?: string;
  truckDeliveryTimeId?: number;
}
export interface ContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
export interface ShippingResult {
  shippingCharge: number;
}
export interface TruckViewDto {
  id: number;
  menu: TruckMenu;
  truck: TruckInfo;
}

export interface TruckAboutUs {
  aboutUs: LocalizedField;
}

export interface CountryListDto {
  id: number;
  name: LocalizedField;
  currencyCode: LocalizedField;
  code2: string;
  code3: string;
  governates: governate[];
}
export interface TruckStatus {
  lastKnownLocation?: ShortAddressDto;
  lastKnownAddress?: ShortAddressDto;
  status?: string;
}
export interface ShortAddressDto {
  location?: GeoLocationDto;
  address?: LocalizedField;
  areaId?: number;
  contactPhone?: string;
}
export interface TruckInfo extends IWithTruckStatus {
  id: number;
  location: GeoLocationDto;
  name: LocalizedField;
  deliveryCharge: number;
  collectorChargePercentage: number;
  deliveryTimes: DeliveryTime[];
  minOrderCost?: number;
  maxOrderCost?: number;
  maxCartItemsCount?: number;
  minDeliveryTime?: string;
  allowAboutUs?: boolean;
  allowShippingOutsideCountry?: boolean;
  useDateOnlyForPreOrder?: boolean;
  areas: Area[];
  enableSubstitutionOptions: boolean;
  enableOTPPhoneVerification: boolean;

  paymentMethodsLookups: PaymentMethodsLookups[];
  supportPhone?: string;
  freeDeliveryMinInvoice?: number;
  menu: TruckMenu;
  pics?: TruckPic[];
  services: Array<string>;
  defaultCountry: CountryListDto;
  notes: LocalizedField;
  operatingHours: OperatingTime[];
  logoPic: string;
}
export interface TruckMenu {
  categories: Array<CategoryView>;
  allCategories: Array<CategoryView>;
  allItems: MenuItem[];
  cateringItems?: MenuItem[];
  cateringCategory?: CategoryView;
}
export interface CategoryView {
  parentCategoryId?: number;
  categoryInfo: CategoryInfo;
  items: MenuItem[];
  subCategories: Array<CategoryView>;
}
export interface CategoryInfo {
  id: number;
  title: LocalizedField;
  mainPic?: string;
  extraDeliveryCharge?: number;
  extraDeliveryTimeMinutes?: number;
}
export interface MenuItemInfo {
  isStocked: boolean;
  stockQty: number;
  saleOutStocked: boolean;
  id: number;
  price: number;
  priceBefore: number;
  services: Array<string>;
  mainPic: string;
  title: LocalizedField;
  ingredients: LocalizedField;
  itemPics: ItemPic[];
  isFreeDelivery?: boolean;
  maxQty?: number;
  detailSections: any[];
  setupTime?: string;
  notes: LocalizedField;
  truckName?: LocalizedField;
}
export interface ItemPic {
  pic: string;
}
export interface OrderCartItem {
  notes?: string;
  qty: number;
  unitPrice: number;
  itemId: number;
  extras: Array<OrderCartExtraItem>;
}
export class CartItem {
  $item?: MenuItemInfo;
  $services?: Array<string>;
  notes?: string;
  qty: number;
  unitPrice: number;
  itemId: number;
  extras: Array<CartExtraItem>;

  get $total(): number {
    return round(
      (_.sumBy(this.extras, (d) => d.price * (d.$extraItem.qty || d.qty)) ||
        0) +
        this.$item.price * this.qty
    );
  }
}
export interface OrderCartExtraItem {
  extraItemId: number;
  unitPrice: number;
  qty: number;
}
export class CartExtraItem {
  $extraItem: MenuExtraItem;
  qty: number;
  get id(): number {
    return this.$extraItem.id;
  }
  get price(): number {
    return this.$extraItem.price;
  }
}
export interface MenuExtraItem {
  price: number;
  id: number;
  isSelected: boolean;
  qty: number;
  maxQty: number;
  color: string;
  isStocked: boolean;
  stockQty?: number;
  saleOutStocked: boolean;
  catId?: number;
  title: LocalizedField;
}
export interface MenuExtraCategory {
  extraCategory: ExtraCategoryInfo;
  extraItems: Array<MenuExtraItem>;
}
export interface ExtraCategoryInfo {
  id: number;
  title: LocalizedField;
  displayMode: string;
  minQty: number;
  maxQty: number;
  selected?: number;
}
export interface LocalizedField {
  ar: string;
  en: string;
}
export interface ValidatePromoCodeResponse {
  discountValue: number;
}
export interface ValidatePromoCodeCmd {
  promoCode: string;
  paymentMethod: string;
  itemsTotal: number;
  truckId: number;
  cartItems: OrderCartItem[];
}
export interface DeliveryTimeWrapper {
  from: number;
  to: number;
  isAvailable: boolean;
  isPreOrder: boolean;
  deliveryTime: DeliveryTime;
}
export interface DeliveryTimeRes {
  asSoonAsFrom: number;
  asSoonAsTo: number;
  expectedDeliveredAt?: number;
  allowAsSoonAs: boolean;
  allowPreOrder: boolean;
  useDateOnlyForPreOrder: boolean;
  isPreOrder: boolean;
  isAvailable: boolean;
  preOrderNote: LocalizedField;
  laterOptions: [DeliveryTimeWrapper];
}
export interface MenuItem {
  itemInfo: MenuItemInfo;
  extras: Array<MenuExtraCategory>;
  qty: number;
  catId?: number;
}

export function round(num: number): number {
  return Math.round(num * 1000 + Number.EPSILON) / 1000;
}

export interface PaymentMethodsLookups {
  id: number;
  name: LocalizedField;
  paymentMethod: string;
}
export interface DeliveryTime {
  id: number;
  deliverFrom: string;
  deliverTo: string;
  type: string;
}
export interface MembershipInfo {
  customerName: string;
  id: number;
  phone: string;
  civilId: string;
  fileNumber: string;
  membershipNo: string;
}
export interface TruckSBOptions {
  simpleBrokerOptions: SBOptions;
}
export interface TruckBranch {
  id: number;
  name: LocalizedField;
  location?: BranchLocation;
  status: string;
  area?: Area;
  address?: string;
}
export interface BranchLocation {
  address: LocalizedField;
  location: GeoLocationDto;
  contactPhone?: string;
}
export interface TruckSBOptionsWithDetails extends IWithTruckStatus {
  id: number;
  name: LocalizedField;
  lastKnowState?: string;
  supportPhone: string;
  areas: TruckArea[];
  pics: TruckPic[];
  enablePreOrder: boolean;
  allowAboutUs: boolean;
  hasMultipleBranches: boolean;
  logoPic?: string;
  mainPic?: string;
  defaultCountry: CountryListDto;
  simpleBrokerOptions?: SBOptions;
}

export interface SBOptions {
  coverPhoto: string;
  headerPhoto: string;
  logo: string;
  terms: LocalizedField;
  bgColor1: string;
  bgColor2: string;
  bgColor3: string;
  bgColor4: string;
  fontColor1: string;
  fontColor2: string;
  menuAlert: MenuAlert;
  useCivilId: boolean;
  useFileNumber: boolean;
  useMembershipNo: boolean;
  forceUsingMembership: boolean;
  showSoonestDeliveryTime: boolean;
  useMultiVendor: boolean;
  iosAppLink?: string;
  androidAppLink?: string;
  enableRegistrationModule: boolean;
  disableGuestOrdering: boolean;
  showSoonestDeliveryTimeOnlyIfPreOrder: boolean;
  showConfirmDeliveryTimeAtCheckout: boolean;
  showConfirmDeliveryTimeAtCheckoutOnlyIfPreOrder: boolean;
  showMostSoldItems: boolean;
  showRecentlyAddedItems: boolean;
  mostSoldItemsImg: string;
  recentlyAddedItemsImg: string;
  cateringImg?: string;
  socialLinks?: any;
}
export var SocialIcons: any = {
  facebook: "fab fa-facebook-f",
  twitter: "fab fa-twitter",
  instagram: "fab fa-instagram",
  snapchat: "fab fa-snapchat-ghost",
  youtube: "fab fa-youtube",
  whatsapp: "fab fa-whatsapp",
  phone: "fas fa-phone-volume",
  linkedin: "fab fa-linkedin-in",
};
export interface MenuAlert {
  title: LocalizedField;
  message: LocalizedField;
  alertEnabled: boolean;
}
export enum AppEventType {
  OpenApp = "OpenApp",
  ViewMenu = "ViewMenu",
  ViewCategory = "ViewCategory",
  ViewItem = "ViewItem",
  ViewCart = "ViewCart",
  AddToCart = "AddToCart",
  ViewAddress = "ViewAddress",
  ConfirmAddress = "ConfirmAddress",
  ConfirmPickupTime = "ConfirmPickupTime",
  Checkout = "Checkout",
  PlaceOrder = "PlaceOrder", //succesfully placed order
  //Errors
  CheckoutError = "CheckoutError",
}
