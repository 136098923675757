import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  HostListener,
  ElementRef,
} from "@angular/core";
import { ApiService } from "../api.service";
import {
  TruckInfo,
  TruckViewDto,
  MenuItem,
  CartItem,
  CategoryInfo,
  CategoryView,
  TruckMenu,
  AppEventType,
  TruckPic,
} from "../models";
import { Observable, Subscription } from "rxjs";
import { UtilsService } from "../utils.service";
import { CartService } from "../cart.service";
import { Cart } from "../cart";
import { Router } from "@angular/router";
import { timeout } from "q";
import * as _ from "lodash";
import { ExtraCategoryAlertComponent } from "../extra-category-alert/extra-category-alert.component";
import { MenuAlertComponent } from "../menu-alert/menu-alert.component";
import { environment } from "src/environments/environment";
import { getAttrsForDirectiveMatching } from "@angular/compiler/src/render3/view/util";
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from "angular-animations";
let lastOpenedCategoryId = undefined;
import { data } from "jquery";
import { CategoryMode } from "../enums/category-mode";
import { OwlOptions } from "ngx-owl-carousel-o";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "../google-tag-manager.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()],
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("widgetsContent", { read: ElementRef })
  public widgetsContent: ElementRef<any>;

  categoryModes = CategoryMode;
  categoryMode: string;
  showCategoryMode = environment.categoryMode;
  @ViewChild(ExtraCategoryAlertComponent)
  extraCatAlertSelector: ExtraCategoryAlertComponent;
  @ViewChild(MenuAlertComponent) menuAlertSelector: MenuAlertComponent;
  cart: Cart;
  lastOpenedCategoryId: any = lastOpenedCategoryId;
  menu: TruckMenu;
  cateringItems: MenuItem[];
  truckPics: TruckPic[];
  isLoadingMenu = false;
  isOpen = true;
  isWorkingNow = true;
  allowPreOrder = false;
  selectMode: string = "";
  forCatering: boolean = false;
  topSlidersOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: true,
    dots: false,
    navSpeed: 700,
    rtl: this.translate.currentLang == "ar" ? true : false,
    navText: [" "],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  isShipping = false;
  listmode: boolean = true;
  showTopCategory: boolean = false;
  private subMenuChanged: Subscription;
  private subMenuChanging: Subscription;

  onScrollTimeOut: any;
  diabledAutoActiveScroll: boolean;
  diabledAutoActiveScrollTimeOut: any;
  onScrollYTimeOut: any;
  constructor(
    private api: ApiService,
    private router: Router,
    public utils: UtilsService,
    public srvCart: CartService,
    public translate: TranslateService,
    private gtagService: GoogleTagManagerService
  ) {
    this.cart = srvCart.getCart();
    if (this.cart.serviceType_ex == "catering") {
      srvCart.setServiceType("", "");
    }
    this.api.trackEvent(AppEventType.ViewMenu);
    if (environment.default_category_id) {
      this.api
        .viewCategory(environment.default_category_id)
        .subscribe((cat) => {
          if (cat.subCategories && cat.subCategories.length)
            this.router.navigate(["/sub-category/" + cat.categoryInfo.id]);
          else
            this.router.navigate([
              "/categories/" + cat.categoryInfo.id + "/items",
            ]);
        });
      return;
    }
    this.subMenuChanging = api.menuChanging.subscribe((loadingState) => {
      this.isLoadingMenu = loadingState;
    });
    this.subMenuChanged = api.menuChanged.subscribe((d) => {
      if (!d) return;
      this.menu = d;
      this.selectedCateory =
        "catid" + this.menu?.categories[0]?.categoryInfo?.id;
      this.cateringItems = _.filter(this.menu.allItems, (d) =>
        _.includes(d.itemInfo.services, "catering")
      );

      let outOfStockItems = _.filter(
        this.menu.allItems,
        (i) => i.itemInfo.isStocked && !i.itemInfo.stockQty
      );
      this.outOfStockItems = outOfStockItems.length;
    });
  }

  get availableItems() {
    return (
      (this.cart?.serviceType_ex == "catering"
        ? this.menu && this.menu.allItems.length - this.outOfStockItems
        : this.cateringItems && this.cateringItems.length) || 0
    );
  }
  truck?: TruckViewDto;
  searchText: string;
  category: CategoryView;
  isLoading: boolean = false;
  showTermsLink: boolean = environment.terms_at_end_of_menu;
  outOfStockItems = 0;
  androidLink?: string;
  iosLink?: string;
  showMostSoldItems: boolean = false;
  showRecentlyAddedItems: boolean = false;
  mostSellingCat?: CategoryView;
  recentlyAddedCat?: CategoryView;
  selectedService?: string;
  selectedCateory?: string = "";

  services = [];

  lastOpenedCategory() {
    return this.lastOpenedCategoryId;
  }
  setDefaultListVisew() {
    if (this.categoryMode == this.categoryModes.GRID) {
      this.showCategoryCols();
    }
    if (this.categoryMode == this.categoryModes.LIST) {
      this.showCategoryRow();
    }
  }
  openCategory(cat, force = false) {
    let that = this;
    setTimeout(function () {
      if (force || cat.id != lastOpenedCategoryId)
        lastOpenedCategoryId = cat.id;
      else lastOpenedCategoryId = null;
      if (!that.lastOpenedCategoryId)
        that.lastOpenedCategoryId = lastOpenedCategoryId;
      else that.lastOpenedCategoryId = null;
    }, 500);
  }
  ngAfterViewInit() {
    let offset: any = $($(".expanded")[0]).offset();
    offset = (offset && offset.top - 60) || 0;

    // $(window).scroll(function () {
    //   if ($(this).scrollTop() > 120) {
    //     $(".subHeader").addClass("fixed-header");
    //   } else {
    //     $(".subHeader").removeClass("fixed-header");
    //   }
    // });

    $("body")
      .not($("ul.dropdown-menu dropdown-toggle"))
      .click(function () {
        let buttonRow = document.querySelector(".side-menu  .dropdown-toggle");
        let buttonRow2 = document.querySelector(".section-dropdown .dropdown");
        buttonRow ? buttonRow.classList.remove("show") : "";
        buttonRow2 ? buttonRow2.classList.remove("show") : "";
      });

    // if ($(".section-dropdown .dropdown.show:after").click()) {

    // }
    // $([document.documentElement, document.body]).animate({
    //   scrollTop: offset
    // }, 500);
  }

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {
    if (this.diabledAutoActiveScroll) {
      return;
    }
    if (this.onScrollTimeOut) {
      clearTimeout(this.onScrollTimeOut);
    }
    this.onScrollTimeOut = setTimeout(() => {
      if (this.menu?.categories?.length > 0) {
        this.menu?.categories.forEach((element: any) => {
          if (element.id) {
            // this.selectedCateory = element.id.toString();
            let div = document.getElementById("catid" + element.id);
            var distanceFromTop =
              div.getBoundingClientRect().top + window.pageYOffset - 180;
            if (window.pageYOffset >= distanceFromTop) {
              this.selectedCateory = "catid" + element.id.toString();
              this.scrollY(element.id);
            }
          }
        });
      }
    }, 100);
  }
  scrollY(id) {
    if (this.onScrollYTimeOut) {
      clearTimeout(this.onScrollYTimeOut);
    }
    this.onScrollYTimeOut = setTimeout(() => {
      let catTitle = document.getElementById("catid" + id + "Head");
      var distanceFromleft = catTitle.getBoundingClientRect().left;

      this.widgetsContent.nativeElement.scroll({
        top: 0,
        left:
          this.widgetsContent.nativeElement.scrollLeft + distanceFromleft - 150,
        behavior: "smooth",
      });
    }, 100);
  }
  toTopCat(id) {
    this.diabledAutoActiveScroll = true;
    this.selectedCateory = "catid" + id.toString();
    let div = document.getElementById(this.selectedCateory.toString());
    var distanceFromTop =
      div.getBoundingClientRect().top + window.pageYOffset - 133;
    window.scroll({
      top: distanceFromTop,
      left: 0,
      behavior: "smooth",
    });
    this.scrollY(id);

    clearTimeout(this.diabledAutoActiveScrollTimeOut);
    this.diabledAutoActiveScrollTimeOut = setTimeout(() => {
      this.diabledAutoActiveScroll = false;
    }, 1000);
  }

  ngOnInit() {
    $(".home-screen-only").css({ visibility: "visible", display: "flex" });

    this.isLoading = true;
    this.api.viewTruck().subscribe(
      (d) => {
        if (d.truck.id == 472 || d.truck.id == 473) {
          this.router.navigate([
            "/categories/" +
              d?.menu?.categories[0]?.categoryInfo?.id +
              "/items",
          ]);
        }
        this.truck = d;
        if (d.truck.allowShippingOutsideCountry) {
          this.isShipping =
            this.cart.orderInfo.pickupAddress.countryId &&
            this.cart.orderInfo.pickupAddress.countryId !=
              d.truck.defaultCountry.id;
        }

        this.isLoading = false;
        if (!this.utils.isNavigationAllowed(this.truck.truck)) {
          this.router.navigate(["/home"]);
          return;
        }
        let outOfStockItems = _.filter(
          this.truck.menu.allItems,
          (i) => i.itemInfo.isStocked && !i.itemInfo.stockQty
        );
        this.outOfStockItems = outOfStockItems.length;

        let _self = this;
        this.menu = this.truck.menu;
        this.utils.fbTrack("PageView");
      },
      (err) => {
        this.isLoading = false;
      }
    );
    this.api.viewTruck().subscribe((t) => {
      if (this.cart.serviceType_ex == "catering")
        this.selectedService = "soonest_delivery";

      if (_.includes(t.truck.services, "delivery")) {
        this.services.push("soonest_delivery");
        this.services.push("delivery");
        //we should add the as soon as as possible special method
      }
      if (_.includes(t.truck.services, "pickup")) {
        this.services.push("pickup");
      }

      if (!this.selectedService) this.selectedService = this.services[0];

      if (!this.cart.serviceType_ex) {
        this.setService(this.selectedService, true);
      }
    });
    this.api.viewSBOptionsDetails().subscribe((res) => {
      console.log("🚀 ~ file: menu.component.ts:350 ~ MenuComponent ~ this.api.viewSBOptionsDetails ~ res:", res)
      
      this.isOpen = this.utils.isNowBetween(res.operatingFrom, res.operatingTo);
      this.isWorkingNow = this.isOpen;
      if (!this.utils.isNavigationAllowed(res)) {
        this.isOpen = false;
        this.allowPreOrder = false;
        this.isWorkingNow = false;
      } else if (this.allowPreOrder) {
        this.isOpen = true;
      }

      if (res.pics && res.pics.length > 0)
        this.truckPics = _.filter(res.pics, (c) => c.showOnSlider == true);
      if (!this.truckPics || !this.truckPics.length)
        this.truckPics = _.filter(res.pics, (d) => d.isMain);

      if (res && res.simpleBrokerOptions) {
        this.androidLink = res.simpleBrokerOptions.androidAppLink;
        this.iosLink = res.simpleBrokerOptions.iosAppLink;
        this.showMostSoldItems = res.simpleBrokerOptions.showMostSoldItems;
        this.showRecentlyAddedItems =
          res.simpleBrokerOptions.showRecentlyAddedItems;
        if (this.showMostSoldItems) {
          this.mostSellingCat = {
            categoryInfo: {
              id: -1,
              title: { en: "Most Selling", ar: "الأكثر مبيعاً" },
              mainPic: res.simpleBrokerOptions.mostSoldItemsImg,
            },
          } as CategoryView;
        }

        if (this.showRecentlyAddedItems) {
          this.recentlyAddedCat = {
            categoryInfo: {
              id: -2,
              title: { en: "Recently Added", ar: "المضافة حديثاً" },
              mainPic: res.simpleBrokerOptions.recentlyAddedItemsImg,
            },
          } as CategoryView;
        }
      }
      if (
        res.simpleBrokerOptions &&
        res.simpleBrokerOptions.menuAlert &&
        res.simpleBrokerOptions.menuAlert.alertEnabled
      ) {
        setTimeout(() =>
          this.menuAlertSelector.showMenuAlert(() => {},
          res.simpleBrokerOptions.menuAlert)
        );
      }
    });

    if (sessionStorage.getItem("categoryMode")) {
      if (
        (sessionStorage.getItem("categoryMode") == this.categoryModes.GRID &&
          (environment.categoryMode == this.categoryModes.GRID ||
            environment.categoryMode == this.categoryModes.ALL)) ||
        (sessionStorage.getItem("categoryMode") == this.categoryModes.LIST &&
          (environment.categoryMode == this.categoryModes.LIST ||
            environment.categoryMode == this.categoryModes.ALL))
      ) {
        this.categoryMode = sessionStorage.getItem("categoryMode");
        // this.setDefaultListVisew()
      } else {
        if (environment.categoryMode == this.categoryModes.GRID) {
          sessionStorage.setItem("categoryMode", this.categoryModes.GRID);
        }
        if (
          environment.categoryMode == this.categoryModes.LIST ||
          (environment.categoryMode == this.categoryModes.ALL &&
            environment.defaultCategoryMode != this.categoryModes.GRID)
        ) {
          sessionStorage.setItem("categoryMode", this.categoryModes.LIST);
        }
        if (
          environment.categoryMode == this.categoryModes.GRID ||
          (environment.categoryMode == this.categoryModes.ALL &&
            environment.defaultCategoryMode == this.categoryModes.GRID)
        ) {
          sessionStorage.setItem("categoryMode", this.categoryModes.GRID);
        }
        this.categoryMode = sessionStorage.getItem("categoryMode");
      }
    }
    if (!sessionStorage.getItem("categoryMode")) {
      if (this.categoryModes.GRID == environment.categoryMode) {
        sessionStorage.setItem("categoryMode", this.categoryModes.GRID);
      }
      if (this.categoryModes.LIST == environment.categoryMode) {
        sessionStorage.setItem("categoryMode", this.categoryModes.LIST);
      }
      if (
        this.categoryModes.ALL == environment.categoryMode &&
        environment.defaultCategoryMode != this.categoryModes.GRID
      ) {
        sessionStorage.setItem("categoryMode", this.categoryModes.LIST);
      }
      if (
        this.categoryModes.ALL == environment.categoryMode &&
        environment.defaultCategoryMode == this.categoryModes.GRID
      ) {
        sessionStorage.setItem("categoryMode", this.categoryModes.GRID);
      }
      this.categoryMode = sessionStorage.getItem("categoryMode");
    }
    this.setDefaultListVisew();
  }

  setService(service, isDefault: boolean = false) {
    if (service == "soonest_delivery") {
      this.selectedService = service;
      this.srvCart.setServiceType("delivery", "soonest_delivery");
      this.cart.orderInfo.pickupTime = null;
      this.cart.orderInfo.pickupTimeTo = null;
      this.api.getDeliveryTimes(new Date(), "delivery").subscribe((t) => {
        if (this.cart.serviceType_ex == "delivery") {
          this.cart.orderInfo.pickupTime = t.asSoonAsTo;
        }
        if (this.cart.serviceType_ex == "soonest_delivery") {
          // t.expectedDeliveredAt = t.asSoonAsTo;
          this.cart.orderInfo.pickupTime = t.expectedDeliveredAt;
        }
        //this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
        // if (t.asSoonAsFrom != t.asSoonAsTo)
        //   this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
      });
    } else {
      if (service !== "delivery" && isDefault == false) {
        this.srvCart.setServiceType(service);
      }
    }
    if (service === "delivery") {
      this.router.navigate(["time-date"]);
      return;
    }
    if (!this.forCatering && service == "pickup") {
      this.selectMode = "branches";
      this.api.getDeliveryTimes(new Date(), "pickup").subscribe((t) => {
        this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
        if (t.asSoonAsFrom != t.asSoonAsTo)
          this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
      });
    }
    if (isDefault) {
      return;
    }
    this.router.navigate(["service"]);
  }
  // start category section
  showCategoryRow() {
    let buttonRow = document.querySelector(".grid-row"),
      buttonCols = document.querySelector(".grid-cols"),
      cardsSec = document.querySelector(".cards-sec");

    buttonRow ? buttonRow.classList.add("secondary-color") : "";
    cardsSec ? cardsSec.classList.add("list-mode") : "";
    buttonCols ? buttonCols.classList.remove("secondary-color") : "";
    sessionStorage.setItem("categoryMode", this.categoryModes.LIST);
    this.listmode = true;
  }

  showCategoryCols() {
    let buttonRow = document.querySelector(".grid-row"),
      buttonCols = document.querySelector(".grid-cols"),
      cardsSec = document.querySelector(".cards-sec");

    buttonCols ? buttonCols.classList.add("secondary-color") : "";
    cardsSec ? cardsSec.classList.remove("list-mode") : "";
    buttonRow ? buttonRow.classList.remove("secondary-color") : "";
    sessionStorage.setItem("categoryMode", this.categoryModes.GRID);

    this.listmode = false;
  }
  //that's not correct

  // getLoadedTruck(){ // if change branch in menu screen, must load new truck menu
  //   this.cart = this.srvCart.getCart();
  //   if(this.truck && this.truck.truck)
  //     this.truck.truck = this.cart.$truck;
  //   if(this.cart.$truck){
  //     let outOfStockItems = _.filter(this.cart.$truck.menu.allItems, i=>i.itemInfo.isStocked && !i.itemInfo.stockQty);
  //     this.outOfStockItems = outOfStockItems.length;
  //     this.availableItems = this.cart.$truck.menu.allItems.length - this.outOfStockItems;
  //   }
  //   return this.cart;
  // }

  checkExtraCartCat(item) {
    let _self = this;
    this.category = this.srvCart.getCart().baseCategory(item.itemInfo.id);
    if (this.cart.cartItems.length != 0) {
      let foundCat = _.find(
        this.cart.cartItems,
        (ci) => this.getCatId(ci.$item.id) == this.category.categoryInfo.id
      );
      if (
        !foundCat &&
        ((this.category.categoryInfo.extraDeliveryCharge &&
          this.category.categoryInfo.extraDeliveryCharge != 0) ||
          (this.category.categoryInfo.extraDeliveryTimeMinutes &&
            this.category.categoryInfo.extraDeliveryTimeMinutes != 0))
      )
        setTimeout(() =>
          this.extraCatAlertSelector.selectExtraCatAlert(() => {},
          _self.category)
        );
    }
  }

  getCatId(itemId) {
    var baseCat = this.cart.baseCategory(itemId);
    if (baseCat) return baseCat.categoryInfo.id;
  }

  ngOnDestroy() {
    if (this.subMenuChanged) this.subMenuChanged.unsubscribe();
    $(".home-screen-only").css({ visibility: "hidden", display: "none" });
    //$('.page-side').addClass('sm-hidden');
  }
  startOrder() {
    if (this.cart.cartItems && this.cart.cartItems.length) {
      this.router.navigate(["/pre-checkout"]);
    }
  }
  onCategoryClick(cat: any) {
    if (cat.subCategories && cat.subCategories.length)
      this.router.navigate(["/sub-category/" + cat.id]);
    else
      this.router.navigate(["/categories/" + cat.categoryInfo.id + "/items"]);
  }
  checkOutOfStock(item) {
    return this.srvCart.isOutOfStock(item);
  }
  addItem(item: MenuItem) {
    this.checkExtraCartCat(item);
    let qty = 1;
    var found = _.find(
      this.cart.cartItems,
      (c) => c.$item.id == item.itemInfo.id
    );
    if (found) {
      found.qty += 1;
      qty = found.qty;
    } else {
      this.srvCart.addItem(
        _.assignIn(new CartItem(), {
          $item: item.itemInfo,
          extras: [],
          qty: 1,
          notes: "",
        })
      );
    }
    item.qty += 1;
    this.utils.fbTrack("AddToCart", {
      currency: "KWD",
      price: item.itemInfo.price,
      value: item.itemInfo.price,
      item_id: item.itemInfo.id,
      content_type: "product",
      content_ids: item.itemInfo.id,
    });

    this.utils.ttqTrack("AddToCart", {
      content_id: item.itemInfo.id,
      content_type: "product",
      content_name: item.itemInfo.title.en,
      quantity: item.qty,
      price: item.itemInfo.price,
      value: item.itemInfo.price,
      currency: "KWD",
    });

    this.utils.snapchatTrack("ADD_CART");

    this.api.trackEvent(AppEventType.AddToCart, item.itemInfo.id, { qty: qty });
    this.srvCart.saveCart();
  }
  removeItem(item: MenuItem) {
    var found = _.find(
      this.cart.cartItems,
      (c) => c.$item.id == item.itemInfo.id
    );
    if (found) {
      found.qty -= 1;
      item.qty -= 1;
      if (found.qty == 0) {
        this.srvCart.removeCartItem(found);
      }
    }
    this.srvCart.saveCart();

    this.gtagService.addToCartEventEmitter(
      {
        id: item.itemInfo.id.toString(),
        name: item.itemInfo.title.en,
        category: item.catId.toString() || "",
        price: item.itemInfo.price,
        quantity: item.qty,
      },
      "remove"
    );
  }

  navigateToUrl(pic) {
    if (pic.linkedItem) this.router.navigate(["/item/" + pic.linkedItem.id]);
    else if (pic.linkedCategory)
      this.router.navigate(["/categories/" + pic.linkedCategory.id + "/items"]);
    else if (pic.linkedUrl) window.location.href = pic.linkedUrl;
  }
  navigateTo(link) {
    this.router.navigate([link]);
  }
}
